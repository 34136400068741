<template>
    <zw-sidebar @shown="shown" :title="$t('settings.test_email.title')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.email"
                                        name="email"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-select-group v-model="form.template"
                                         :options="getEmailTemplates()"
                                         name="template"
                                         :label-prefix="labelPrefix"
                                         text-field="name"
                                         value-field="id"
                                         @change="templateSelected"
                        ></zw-select-group>
                    </b-col>

                    <b-col cols="12">
                        <zw-input-group v-model="form.subject"
                                        name="subject"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <label>{{ $t(labelPrefix + 'body') }}</label>
                        <zw-ckeditor v-model="form.body"></zw-ckeditor>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.test') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'TestEmailModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                email: '',
                template: '',
                subject: '',
                body: '',
            },
            form: {},
            labelPrefix: 'settings.test_email.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getEmailTemplates']),
        shown() {
            this.loading = false;
        },
        templateSelected() {
            const template = this.getEmailTemplates().find(template => template.id == this.form.template)

            if (template) {
                this.$set(this.form, 'subject', template.subject)
                this.$set(this.form, 'body', template.body)
            }
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('EmailTemplate/sendTestEmail', this.form)
                        .then((response) => {
                            if (response.data.status) {
                                this.$root.showMessage(
                                    this.$t('common.form.title.success'),
                                    'OK',
                                    'success'
                                )
                            } else {
                                this.$root.showMessage(
                                    this.$t('common.form.title.error'),
                                    response.data.message,
                                    'dangers'
                                )
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>